import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <article className="m-auto">
        <section className="container m-auto sm:py-10 text-center">
          <h2 className="font-serif text-3xl py-2 sm:pb-6">Mahalo</h2>
          <StaticImage
            alt=""
            src="../images/Mahalo.jpg"
          />
        </section>
      </article>
    </Layout>
  )
}

export default IndexPage
